import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { HomeFixedContent } from "../components/MainContentHome"

const HomePage = () => {
  if (typeof window !== 'undefined') {
    window.iframeLoaded = () => {
      var iframeHomeBlogList = document.getElementById('homeBlogList');
      if(iframeHomeBlogList) {
            iframeHomeBlogList.height = (iframeHomeBlogList.contentWindow.document.body.scrollHeight + 50) + "px";
      }   
    }
  }
  return(
    <Layout>
      <SEO title="Tecnologia, carreira, liderança" />
      <HomeFixedContent />
      <iframe
        id="homeBlogList"
        loading="lazy"
        src="/homeBlogList"
        title="Blog posts"
        width="100%"
        height={0}
      />
    </Layout>
  )
}

export default HomePage
